import { createStyles, Select, TextInput } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  root: {
    position: "relative",
  },

  input: {
    height: "auto",
    paddingTop: 18,
  },

  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: theme.spacing.sm / 2,
    zIndex: 1,
  },
}));

export function ContainedInputs() {
  // You can add these classes as classNames to any Mantine input, it will work the same
  const { classes } = useStyles();

  return (
    <>
      <Select
        style={{ marginTop: 20, zIndex: 2 }}
        data={["React", "Angular", "Svelte", "Vue"]}
        placeholder="Pick one"
        label="Team"
        classNames={classes}
      />
      <TextInput label="Amount (ETH)" placeholder="0.0" classNames={classes} />
    </>
  );
}
