import { BrowserRouter } from "react-router-dom";
import { chain, configureChains, createClient, WagmiConfig } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { ConnectKitButton, ConnectKitProvider } from "connectkit";
import { HeroText } from "./components/header";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { InjectedConnector } from "wagmi/connectors/injected";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import {
  ActionIcon,
  Button,
  Card,
  Container,
  createStyles,
  Grid,
  Group,
  Header,
  Menu,
  Progress,
  SimpleGrid,
  Text,
  useMantineColorScheme,
} from "@mantine/core";
import { StatsControls } from "./components/groups";
import { ContainedInputs } from "./components/inputs";
import {
  IconBookmark,
  IconCalendar,
  IconChevronDown,
  IconMoonStars,
  IconSun,
  IconTrash,
} from "@tabler/icons";

export function ProgressCard() {
  return (
    <Card
      withBorder
      radius="md"
      p="xl"
      sx={(theme) => ({
        backgroundColor:
          theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
      })}
    >
      <Text size="xs" transform="uppercase" weight={700} color="dimmed">
        Potential Winnings
      </Text>
      <Text size="lg" weight={500}>
        $5.431 / $10.000
      </Text>
      <Progress value={54.31} mt="md" size="lg" radius="xl" />
    </Card>
  );
}

const useStyles = createStyles((theme) => ({
  button: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  menuControl: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    border: 0,
    borderLeft: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white
    }`,
  },
}));

export function SplitButton() {
  const { classes, theme } = useStyles();
  const menuIconColor =
    theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 5 : 6];

  return (
    <Group noWrap spacing={0}>
      <Button className={classes.button}>Send</Button>
      <Menu transition="pop" position="bottom-end">
        <Menu.Target>
          <ActionIcon
            variant="filled"
            color={theme.primaryColor}
            size={36}
            className={classes.menuControl}
          >
            <IconChevronDown size={16} stroke={1.5} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            icon={<IconCalendar size={16} stroke={1.5} color={menuIconColor} />}
          >
            Schedule for later
          </Menu.Item>
          <Menu.Item
            icon={<IconBookmark size={16} stroke={1.5} color={menuIconColor} />}
          >
            Save draft
          </Menu.Item>
          <Menu.Item
            icon={<IconTrash size={16} stroke={1.5} color={menuIconColor} />}
          >
            Delete
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
}

export default function App() {
  const { chains, provider } = configureChains(
    [chain.arbitrum, chain.goerli],
    [publicProvider()]
  );

  const client = createClient({
    autoConnect: true,
    provider,
    connectors: [
      new MetaMaskConnector({
        chains,
      }),
      new CoinbaseWalletConnector({
        chains,
        options: {
          appName: "wagmi",
        },
      }),
      new WalletConnectConnector({
        chains,
        options: {
          qrcode: true,
        },
      }),
      new InjectedConnector({
        chains,
        options: {
          name: "Injected",
          shimDisconnect: true,
        },
      }),
    ],
  });

  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  return (
    <>
      <WagmiConfig client={client}>
        <ConnectKitProvider>
          <BrowserRouter>
            <Header height={60} px="md">
              <Group position="apart" sx={{ height: "100%" }}>
                {/*<MantineLogo size={30} />*/}

                <Group
                  sx={{ height: "100%" }}
                  spacing={0}
                  // className={classes.hiddenMobile}
                >
                  {/*  <a href="#" className={classes.link}>*/}
                  {/*    Home*/}
                  {/*  </a>*/}
                  {/*  <HoverCard*/}
                  {/*    width={600}*/}
                  {/*    position="bottom"*/}
                  {/*    radius="md"*/}
                  {/*    shadow="md"*/}
                  {/*    withinPortal*/}
                  {/*  >*/}
                  {/*    <HoverCard.Target>*/}
                  {/*      <a href="#" className={classes.link}>*/}
                  {/*        <Center inline>*/}
                  {/*          <Box component="span" mr={5}>*/}
                  {/*            Features*/}
                  {/*          </Box>*/}
                  {/*          <IconChevronDown*/}
                  {/*            size={16}*/}
                  {/*            color={theme.fn.primaryColor()}*/}
                  {/*          />*/}
                  {/*        </Center>*/}
                  {/*      </a>*/}
                  {/*    </HoverCard.Target>*/}

                  {/*    <HoverCard.Dropdown sx={{ overflow: "hidden" }}>*/}
                  {/*      <Group position="apart" px="md">*/}
                  {/*        <Text weight={500}>Features</Text>*/}
                  {/*        <Anchor href="#" size="xs">*/}
                  {/*          View all*/}
                  {/*        </Anchor>*/}
                  {/*      </Group>*/}

                  {/*      <Divider*/}
                  {/*        my="sm"*/}
                  {/*        mx="-md"*/}
                  {/*        color={*/}
                  {/*          theme.colorScheme === "dark" ? "dark.5" : "gray.1"*/}
                  {/*        }*/}
                  {/*      />*/}

                  {/*      <SimpleGrid cols={2} spacing={0}>*/}
                  {/*        {links}*/}
                  {/*      </SimpleGrid>*/}

                  {/*      <div className={classes.dropdownFooter}>*/}
                  {/*        <Group position="apart">*/}
                  {/*          <div>*/}
                  {/*            <Text weight={500} size="sm">*/}
                  {/*              Get started*/}
                  {/*            </Text>*/}
                  {/*            <Text size="xs" color="dimmed">*/}
                  {/*              Their food sources have decreased, and their*/}
                  {/*              numbers*/}
                  {/*            </Text>*/}
                  {/*          </div>*/}
                  {/*          <Button variant="default">Get started</Button>*/}
                  {/*        </Group>*/}
                  {/*      </div>*/}
                  {/*    </HoverCard.Dropdown>*/}
                  {/*  </HoverCard>*/}
                  {/*  <a href="#" className={classes.link}>*/}
                  {/*    Learn*/}
                  {/*  </a>*/}
                  {/*  <a href="#" className={classes.link}>*/}
                  {/*    Academy*/}
                  {/*  </a>*/}
                </Group>

                <Group>
                  <ConnectKitButton
                    theme="retro"
                    showAvatar={true}
                    showBalance={true}
                  />

                  <ActionIcon
                    onClick={() => toggleColorScheme()}
                    size="lg"
                    sx={(theme) => ({
                      backgroundColor:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[6]
                          : theme.colors.gray[0],
                      color:
                        theme.colorScheme === "dark"
                          ? theme.colors.yellow[4]
                          : theme.colors.blue[6],
                    })}
                  >
                    {colorScheme === "dark" ? (
                      <IconSun size={18} />
                    ) : (
                      <IconMoonStars size={18} />
                    )}
                  </ActionIcon>
                </Group>

                {/*<Burger*/}
                {/*  opened={drawerOpened}*/}
                {/*  onClick={toggleDrawer}*/}
                {/*  className={classes.hiddenDesktop}*/}
                {/*/>*/}
              </Group>
            </Header>

            <div
              style={{
                display: "flex",
                alignItems: "inherit",
                justifyContent: "right",
                paddingRight: "25px",
                paddingTop: "25px",
              }}
            ></div>
            <HeroText />
            <Container my="md">
              <SimpleGrid
                cols={2}
                spacing="md"
                breakpoints={[{ maxWidth: "sm", cols: 1 }]}
              >
                <StatsControls />
                <Grid gutter="md">
                  <Grid.Col>
                    <ContainedInputs />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <ProgressCard />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <SplitButton />
                  </Grid.Col>
                </Grid>
              </SimpleGrid>
            </Container>
          </BrowserRouter>
        </ConnectKitProvider>
      </WagmiConfig>
    </>
  );
}
